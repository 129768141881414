import BlockContent from '@sanity/block-content-to-react'
import { format, parseISO } from 'date-fns'
import React, { FC, useContext, useMemo, useState } from 'react'

import { SanityTermsSection } from '../../../graphql/gatsby'
import { useSanityRoleBasedContent } from '../../../lib/sanity'
import { SanityPageContext } from '../../../lib/sanity/context'
import { TermsModal } from '../../terms/TermsModal'
import { TermsModalType } from '../../terms/types'
import { getSerializers } from './serializers'

export type TermsSectionProps = SanityTermsSection

export const TermsSection: FC<TermsSectionProps> = ({ terms, roleBasedContent }) => {
  const { allSanityTerms } = useContext(SanityPageContext)
  const { shouldDisplay } = useSanityRoleBasedContent({ roleBasedContent })
  const [currentlyOpenTermsModal, setCurrentlyOpenTermsModal] = useState<TermsModalType | null>(
    null,
  )
  const serializers = useMemo(() => getSerializers({ setCurrentlyOpenTermsModal }), [])
  const allTerms = allSanityTerms?.nodes

  if (!terms || !shouldDisplay) {
    return null
  }

  return (
    <div>
      <header className="xl:px-10 xl:py-6 px-5 py-6 flex md:justify-between flex-col md:flex-row md:items-center border-b border-brand-rule-grey leading-none">
        <h1 className="xl:text-[40px] font-semibold text-brand-navy text-2xl md:text-[30px]">
          {terms.title}
        </h1>
        {terms.lastModified && (
          <p className="text-black xl:text-lg font-normal text-base">
            <span>Last Modified:</span>&nbsp;
            <span>{format(parseISO(terms.lastModified), 'PP')}</span>
          </p>
        )}
      </header>
      {terms.content && Array.isArray(terms.content) && (
        <BlockContent
          className="md:columns-2 xl:max-w-[1130px] xl:mx-auto xl:mt-[60px] md:mx-5 md:mt-10 mx-2.5"
          blocks={terms.content}
          serializers={serializers}
        />
      )}
      {allTerms && (
        <TermsModal
          allTerms={allTerms}
          currentlyOpenTermsModal={currentlyOpenTermsModal}
          open={!!currentlyOpenTermsModal}
          setCurrentlyOpenTermsModal={setCurrentlyOpenTermsModal}
        />
      )}
    </div>
  )
}
