import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList } from '@dfh-corp-sites/ui'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { SanityLeadershipGrid, SanityTeamMemberLeadership } from '../../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'
import Link from '../../global/Link'

export type LeadershipMemberProps = SanityTeamMemberLeadership & {
  className?: string
}

const LeadershipMember: React.FC<LeadershipMemberProps> = ({
  name,
  title,
  photo,
  slug,
  className,
}) => {
  const image = getGatsbyImageDataForAsset(photo, {
    placeholder: 'blurred',
  })

  const content = (
    <article className={`h-full grid grid-cols-1 ${className}`}>
      <div className="relative w-full h-full">
        {image && (
          <GatsbyImage
            className="w-full h-full"
            class="aspect-square h-96 sm:h-[450px] 2xl:h-[550px] w-full object-contain"
            image={image}
            alt={`Portrait of ${name}${title ? `, ${title}` : ''}`}
          />
        )}
      </div>
      <div>
        <h2 className="text-[40px] text-brand-navy mt-5 tracking-tight font-semibold ">{name}</h2>
        <span className="tracking-tight text-lg text-brand-navy-2">{title}</span>
      </div>
    </article>
  )

  if (slug?.current) {
    return (
      <Link
        className="bg-white md:p-2.5 px-2.5 py-2.5 border border-[#CFCECE] border-b-0 border-l-0"
        to={`/our-story/leadership/${slug.current}`}
        aria-label={`View details for ${name}${title ? `, ${title}` : ''}`}
      >
        {content}
      </Link>
    )
  }

  return content
}

interface TempLeadershipGrid extends SanityLeadershipGrid {
  members?: SanityTeamMemberLeadership[]
}

export type LeadershipGridProps = TempLeadershipGrid

export const LeadershipGrid: React.VFC<LeadershipGridProps> = (props) => {
  const { members } = props as { members: SanityTeamMemberLeadership[] }

  if (!members || members.length === 0) {
    return null
  }

  return (
    <main id="main-content" tabIndex={-1} className="outline-none">
      <div className="flex flex-col gap-2 mb-10 mt-6 px-2.5 xl:px-[50px]">
        <Breadcrumb className="[&_ol]:gap-0">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild href="/our-story">
                <Link to="/our-story">Our Story</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <h1 className="xl:text-6xl md:text-[50px] text-[40px] text-brand-navy font-semibold">
          Our Leadership
        </h1>
      </div>
      <section
        className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 [&>a:nth-child(3n+1)]:xl:pl-10 [&>a:nth-child(3n)]:xl:pr-10"
        aria-label="Leadership team members"
      >
        {members.map((member) => {
          return member && <LeadershipMember {...member} key={member._id} />
        })}
      </section>
    </main>
  )
}
