import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbList } from '@dfh-corp-sites/ui'
import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'

import { SanityScienceTeamGrid, SanityTeamMemberScience } from '../../../graphql/gatsby'
import { getGatsbyImageDataForAsset } from '../../../utils/sanity/image'
import Link from '../../global/Link'

// Simple wrapper to use the exact SanityTeamMemberScience type from GraphQL
export type ScienceTeamMemberProps = SanityTeamMemberScience & {
  className?: string
}

const ScienceTeamMember: React.FC<ScienceTeamMemberProps> = ({
  name,
  degrees,
  title,
  photo,
  slug,
  className,
}) => {
  const image = getGatsbyImageDataForAsset(photo, {
    placeholder: 'blurred',
  })

  const content = (
    <article className={`h-full grid grid-cols-1 ${className || ''} grid-rows-[auto_1fr]`}>
      <div className="relative w-full h-96 sm:h-[450px] 2xl:h-[550px]">
        {image && (
          <GatsbyImage
            className="w-full h-full"
            class="aspect-square h-96 sm:h-[450px] 2xl:h-[550px] w-full object-contain"
            image={image}
            alt={`Portrait of ${name || ''}${title ? `, ${title}` : ''}`}
          />
        )}
      </div>
      <div className="flex flex-col items-start mt-5 justify-start">
        <h2 className="text-2xl text-brand-navy tracking-tight font-semibold ">{name || ''}</h2>
        <div className="text-2xl text-brand-navy font-normal">{degrees}</div>
        <div className="tracking-tight text-lg text-brand-navy-2">{title}</div>
      </div>
    </article>
  )

  if (slug?.current) {
    return (
      <Link
        className="bg-white md:p-2.5 px-2.5 py-2.5 border border-[#CFCECE] border-t-0 border-l-0 xl:[&:nth-child(-n+4)]:border-t md:[&:nth-child(-n+3)]:border-t first:border-t"
        to={`/our-story/science/${slug.current}`}
        aria-label={`View details for ${name || ''}${title ? `, ${title}` : ''}`}
      >
        {content}
      </Link>
    )
  }

  return content
}

export type ScienceTeamGridProps = SanityScienceTeamGrid

export const ScienceTeamGrid: React.FC<ScienceTeamGridProps> = ({ members }) => {
  if (!members || members.length === 0) {
    return null
  }

  // Group members by team type
  const coreTeamMembers = members.filter((member) => member?.teamType === 'coreTeam')
  const advisoryBoardMembers = members.filter((member) => member?.teamType === 'advisoryBoard')

  return (
    <main id="main-content" tabIndex={-1} className="outline-none">
      <div className="flex flex-col gap-2 mb-10 mt-6 px-2.5 xl:px-[50px]">
        <Breadcrumb className="[&_ol]:gap-0">
          <BreadcrumbList>
            <BreadcrumbItem>
              <BreadcrumbLink asChild href="/our-story">
                <Link to="/our-story">Our Story</Link>
              </BreadcrumbLink>
            </BreadcrumbItem>
          </BreadcrumbList>
        </Breadcrumb>
        <h1 className="xl:text-6xl md:text-[50px] text-[40px] text-brand-navy font-semibold">
          Our Science Team
        </h1>
      </div>

      {coreTeamMembers.length > 0 && (
        <>
          <div className="px-2.5 xl:px-[50px] mb-6">
            <h2 className="text-3xl text-black font-semibold">Core Team</h2>
          </div>
          <section
            className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 [&>a:nth-child(4n+1)]:xl:pl-10 [&>a:nth-child(4n)]:xl:pr-10 mb-16"
            aria-label="Core science team members"
          >
            {coreTeamMembers.map(
              (member) => member && <ScienceTeamMember {...member} key={member._id} />,
            )}
          </section>
        </>
      )}

      {advisoryBoardMembers.length > 0 && (
        <>
          <div className="px-2.5 xl:px-[50px] mb-6">
            <h2 className="text-3xl text-black font-semibold">Advisory Board</h2>
          </div>
          <section
            className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 [&>a:nth-child(4n+1)]:xl:pl-10 [&>a:nth-child(4n)]:xl:pr-10"
            aria-label="Advisory board members"
          >
            {advisoryBoardMembers.map(
              (member) => member && <ScienceTeamMember {...member} key={member._id} />,
            )}
          </section>
        </>
      )}
    </main>
  )
}

// Export a fragment for use in page queries
export const scienceTeamGridFragment = graphql`
  fragment ScienceTeamGridMembers on SanityScienceTeamGrid {
    _key
    _type
    members {
      _id
      name
      title
      degrees
      teamType
      photo {
        asset {
          _id
        }
        hotspot {
          height
          width
          x
          y
        }
        crop {
          bottom
          left
          right
          top
        }
      }
      slug {
        current
      }
    }
  }
`
